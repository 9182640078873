%itemText {
  font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
}

.list {
	padding: 0;
}

.listHeader {
  @extend %itemText;
	padding: 16px 32px 0 22px;
  font-weight: bold;
	color: var(--ion-color-primary);
	text-transform: uppercase;
	min-height: auto;
}

.sectionHeader {
  @extend %itemText;
	padding: 54px 32px 16px 22px;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	min-height: auto;
}

.listItem {
  @extend %itemText;
	--min-height: 51px;
	--padding-start: 22px;
	--padding-end: 32px;
	--inner-padding-end: 0;
}

.floatLabel {
	float: right;
}

.checkbox {
	margin-inline-start: 18px;
	--checkmark-color: #fff;
	--background: var(--ion-color-primary);
	--border-radius: 4px;
	--border-width: 1px;
	--border-color: var(--ion-color-medium);
}

.decorCircle {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 16px;
	background-color: var(--ion-color-primary);
	border-radius: 50%;
}
