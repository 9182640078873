.ionFilterModal {
  &__toolbar {
    --background: var(--ion-color-primary-contrast);

    &-title {
      font-size: 20px;

    }
  }

  &__filter {
    border-radius: var(--rounded-md);
    padding: var(--padding-md);
    margin-bottom: var(--margin-md);
    box-shadow: var(--boxshadow-sm);
    font-size: 14px;

    &-title {
      padding: 0;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
    }

    &RadioGroup {
      display: flex;
      flex-direction: column;
    }

    &-rating {
      &Radio {
        width: 24px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: var(--rounded-sm);
        margin-inline: 0;
      }
    }

  }

  &__footer {
    display: flex;
    flex-direction: row-reverse;


    &-btn {
      flex-grow: 1;
      --background: var(--ion-color-primary);
      --color: var(--ion-color-primary-contrast);
      --border-radius: var(--rounded-xl);
      --padding-top: var(--padding-md);
      --padding-bottom: var(--padding-md);

      &Bordered {
        --background-hover: var(--ion-color-primary-contrast);
        --background-activated: var(--ion-color-primary-contrast);
        --background-focused: var(--ion-color-primary-contrast);
        --background: var(--ion-color-primary-contrast);
        --border-color: var(--ion-color-primary);
        --border-width: 3px;
        --border-style: solid;

        color: var(--ion-color-primary);
      }

      &Text {
        font-family: var(--font-montseratt);
        font-weight: 700;
        font-size: 14px;
      }

      &ClinicLength {
        padding-inline: 5px;
        font-weight: 600;
        text-shadow: var(--textshadow-md);
      }
    }
  }
}




