
.container {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    background: white;
    .noServerConnection {
      height: 50%;
      background: url("../../assets/images/noConnection.png") no-repeat bottom;
      background-size: 200px;
      opacity: 45%;
      margin-bottom: 25px;
    }
    .message {
      margin: 0 20px;
      color: var(--ion-color-primary);
    }
  }
  
  .button {
    margin-top: 50px;
    height: 51px;
    text-transform: none;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: unset;
    color: #fff;
    --border-radius: 100px;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
  }
