.ionButtons {
  justify-content: space-between;
  position: fixed;
  width: 100%;
  padding-block: 10px;
  box-shadow: var(--boxshadow-sm  );
  background-color: var(--ion-color-primary-contrast);

  &__btn {
    padding: 5px;
    width: 100%;

    &-icon {
      margin-right: 10px;
    }

    &-text {
      font-size: 15px;
      color: var(--ion-color-dark);
    }
  }
}
