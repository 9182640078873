.ionClinicDetail {
  font-family: var(--font-montseratt);

  &__header {
    padding: var(--padding-md);
    overflow: hidden;

    &-img {
      height: 40dvh;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-top-left-radius: var(--rounded-lg);
      border-top-right-radius: var(--rounded-lg);
    }

    &-emptyImage {
      background-color: var(--ion-color-primary-shade);
      height: 100%;
      object-fit: cover;
    }

    &-title {
      margin-top: -2px;
      padding: var(--padding-sm);
      border-radius: var(--rounded-md);
      background: var(--background-gradient);
      background: var(--ion-color-primary-tint);
      color: var(--ion-color-primary-contrast);
      text-shadow: var(--textshadow-sm);
      font-size: 18px;
      text-align: center;
      font-weight: 600;
    }
  }

  &__reviews {
    margin-bottom: var(--margin-md);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: var(--ion-color-dark);
      margin-bottom: var(--margin-xs);
    }

    &-rating {
      display: flex;
      align-items: center;

      &Icons {
        top: 2px;
      }

      &Desc {
        font-size: 12px;
      }

      &Length {
        font-weight: 700;
        font-size: 14px;
        color: var(--ion-color-tertiary);
      }
    }
  }

  &__content {
    flex-direction: column;
    margin: var(--margin-md);

    &-item {
      margin-bottom: var(--margin-xs);
      padding: var(--padding-md);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      box-shadow: var(--boxshadow-sm);

      &Title {
        font-size: 14px;
        font-weight: 600;
        color: var(--ion-color-dark);
      }

      &Description {
        font-size: 12px;
      }
    }
  }
}

