.img {
  overflow: hidden;
  object-fit: cover;
}

.thumb {
  @extend .img;
  --size: 100%;
  height: 100px;
}

.container {
  margin: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.inputContainer {
  @extend .container;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  color: #bdbdbd;
  line-height: 19px;
  --placeholder-color: #bdbdbd;
  --padding-start: 16px;
}

.inputLabel {
  --placeholder-color:  #bdbdbd;
  color: #333333;
}

.datetimeContainer {
  @extend .inputContainer;
  color: #333333;
}

.datetimeInput {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  --placeholder-color: #bdbdbd;
  --padding-start: 0px;
}

.iconInput {
  margin-left: 10px;
  margin-top: -3px;
  color: #666666;
}

.inputNumber {
  text-align: right;
  margin-right: 5px;
  color: #333333;
}

.inputString {
  text-align: left;
  margin-right: 5px;
  color: #333333;
}

.suggestionsList {
  margin: 15px;
}

.medicineList {
  margin-bottom: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin-left: 14px;
  width: 16px;
  opacity: 1 !important;
}
