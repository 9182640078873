.container {
  width: calc(100vw - 16px);
  display: flex;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  padding-left: 16px;
  padding-bottom: 20px;
  margin-bottom: -20px;
  &::after {
    content: '';
    padding-right: 16px;
  }
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

.container.android {
  padding-bottom: 40px;
}
