
.top {
    background-color: var(--ion-color-primary);
    height: 81px;
}
.container {
    // height: calc((100% - 44px)/2);
    min-height: 296px;
    margin-bottom: 20px;
}
.header {
    padding-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: white;
}

.grid {
    height: 100%;
}
.month {
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
}

.monthRow {
    justify-content: center;
}

.arrowIconRight {
    @extend .month;
    align-self: center;
    margin-left: auto;
    padding-right: 5%;
    color: white;
}

.arrowIconLeft {
    @extend .month;
    align-self: center;
    margin-right: auto;
    padding-left: 5%;
    color: white;
}

.interact {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
}

.nonInteract {
    @extend .interact;
    color: lightgray;
}
.active {
    @extend .interact;
    color: white;
    background-color: var(--ion-color-primary);
    border-radius: 50%;
    cursor: pointer;
}

.day {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.daysOfWeekShortNames {
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: white;
}

.dateSettingText {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    vertical-align: middle;
}

.popover {
    --width: 90%;
    --ion-safe-area-left: calc(5% - 5px);
}

.card {
    margin: 10px 12px;
}

.cardContent {
    padding: 0;
}

.cardRow {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
}

.col {
    font-size: 16px;
    color: black;
}

.col1 {
    @extend .col;
    display: flex;
    width: 60%;
    align-items: center;
    margin-right: 10px;
}

.col2 {
    @extend .col;
}

.colIcon {
    margin-right: 5px;
}

.popoverTitle {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
    color: white;
    background-color: var(--ion-color-primary);
}

.popoverDismissButton {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 18px;
    --color: #fff;
    --box-shadow: none;
}

.cardButton {
    width: 100%;
    text-transform: none;
    --color: #fff;
    font-size: 16px;
    --border-radius: 60px;
}
