.searchButton {
  // padding: 10px 16px;
  border-radius: 9px;
  box-shadow: 0px 8px 24px rgba(192, 195, 200, 0.5);
  margin-right: 0;
  ion-icon {
    margin-right: 10px;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
    color: #BDBDBD;
    font-weight: 600;
  }
}


.car-card {
  width: 83vw;

  &__image {
    background-position: center;
    background-size: cover;
  }

  &__description {
    h6 {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.car-card__price {
        margin-top: 4px;
        margin-bottom: 2px;
      }
    }
  }

}

.markerIcon{
  z-index: 100;
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-53%, -50%);
  margin-top: -18px;
  pointer-events: none;

  &::after {
    content: '';
    height: 3px;
    width: 5px;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: orange;
    border-radius: 50%;
    //box-shadow: 0 0 5px #000000FF;
  }

  img {
    transition: 0.4s all;
  }
}

.dotMarker{

}

.confirmAddressButton {
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 8px 24px rgba(192, 195, 200, 0.5);
  border-radius: 9px;

  &Spinner {
    position: absolute;
    margin: 0 auto;
  }
}

.confirmText {
  font-weight: 600;
}

.loadingOpacity {
  opacity: 0.1;
}
