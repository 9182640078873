.list {
  width: 100%;
}
.listHeader {
  text-align: center;
  padding: 0 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.reviewInfoItem {
  margin: 20px 6px;
  font-size: 16px;
  line-height: 19px;
  color: #bdbdbd;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  --min-height: 50px;
  --padding-start: 16px;
  --padding-end: 0;
  --inner-padding-end: 0;
}

.reviewInfoTextArea {
  --color: black;
  margin-top: 0;
  padding-inline: var(--padding-sm);
  --padding-end: 8px;
}

.button {
  width: 100%;
  height: 51px;
  margin: 0 20px;
  font-size: 16px;
  line-height: 19;
  color: #fff;
  text-transform: none;
  --border-radius: var(--rounded-xl);
}

.modal{
  --width: 100%;
  --height: 300px;
  --border-radius: 6px;
  padding: 16px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert {
  --ion-text-color: #831e7a;
}
