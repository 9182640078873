.list {
  width: 100%;
}

.listHeader {
  padding: 0 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.prescriptionInfoItem {
  margin: 22px 16px;
  font-size: 16px;
  line-height: 19px;
  color: #bdbdbd;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  --min-height: 50px;
  --padding-start: 16px;
  --padding-end: 0;
  --inner-padding-end: 0;
}

.prescriptionInfoTextArea {
  --color: black;
  margin-top: 0;
  --padding-end: 8px;
}

.drugsItems {
  height: 49px;
  font-size: 18px;
  line-height: 19px;
  --padding-start: 16px;
  --padding-end: 16px;
  --inner-padding-end: 12px;
}

.addDrugsItem {
  color: #9400d3;
}

.icon {
  margin-right: 10px;
  color: #686b6f;
}

.button {
  width: 100%;
  height: 51px;
  margin: 16px 16px 20px;
  font-size: 16px;
  line-height: 19;
  color: #fff;
  text-transform: none;
  --border-radius: 100px;
}

.iconAdd{
  color: #9400d3;
  padding-right: 4px;
}