.DashboardContainer {
  padding-block: var(--padding-sm);
  display: grid;
  background-color: var(--ion-color-primary-contrast);

  &__card {
    box-shadow: var(--boxshadow-sm);
    padding: var(--padding-md);
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 135px;
    &-header {
      margin-bottom: var(--margin-sm);
      height: 50px;
      &Icon {
        width: 50px;
        height: 50px;
      }
    }
    &-content {
      padding-inline: 0;
      padding-block: var(--padding-sm);
      &Title {
        font-size: 14px;
        line-height: 1.2;
        word-wrap: normal;
        text-align: center;
        color: var(--ion-color-secondary-contrast);
      }
    }
  }
}

.pink {
  color: #ff0200;
}

.lgGreen {
  color: #76aa5b;
}

.purple {
  color: #7230de;
}

.green {
  color: #3fd28c;
}

.cyan {
  color: #005bff;
}

.turmeric {
  color: #a79a3e;
}

.antiquebrass {
  color: #cc9165;
}

.linkButton {
  text-decoration: none;
}
