.backdrop {
    animation-name: backDropLoading;
    animation-duration: .2s;
    background: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal{
        animation-name: modalLoading;
        animation-duration: .2s;
        z-index: 100;
        width: 90%;
        max-width: 520px;
        background: white;
        border-radius: 8px;
        padding: 20px 18px;
        .title{
            color: var(--ion-color-primary);
            text-align: center;
            font-weight: bold;
            margin: 0 0 20px 0;
        }
    }
}

@keyframes backDropLoading{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes modalLoading{
    from{
        transform: scale(.5);
    }
    to{
        transform: scale(1);
    }
}
