.FooterTab {
  background-color: var(--ion-color-primary-contrast);

  &__container {
    border-top-left-radius: calc(var(--rounded-lg) - 2px);
    border-top-right-radius: calc(var(--rounded-lg) - 2px);
    border: 0;
    box-shadow: var(--boxshadow-sm), var(--boxshadow-sm);
    height: 4rem;
  }
  &__btn {
    background-color: var(--ion-color-primary-contrast);
    &-label {
      font-size: 10px;
    }
  }


}
