.changeNumberInputWrapper{
  display: flex;
  justify-content: space-around;
  .changeNumberInput{
    display: flex;
    flex-direction: column;
    align-items: center;
    .button{
      border-radius: 100%;
      width: 60px;
      height: 60px;
      background: rgba($color: #000000, $alpha: .1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
    }
    .durationInputWrapper{
      border: 1px solid rgba($color: #000000, $alpha: .1);
      border-radius: 4px;
      text-align: center;
      margin: 8px;
      padding: 10px 0;
      .durationInputField{
        align-items: center;
        margin: 0 auto;
        input{
          padding: 0;
        }
      }
    }
  }
}

.validateButtons{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
