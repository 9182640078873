.header {
  background-color: var(--ion-color-primary);
}

.toolbar {
  padding: 0 16px;
  color: #fff;
  --background: transparent;
}

.toolbarFirst {
  height: 38px;
  --min-height: 38px;
}

.menuButton {
  margin: 0 !important;
  font-size: 16px !important;
  line-height: 19px;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --color: #fff;
}

.menuIcon {
  color: #fff;
}

.pageTitle {
  font-size: 30px;
  line-height: 36px;
}

.profilePhotoContainer {
  text-align: center;
  padding-bottom: 24px;
}

.profilePhoto {
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  z-index: 1000;
  width: 157px;
  height: 157px;
}

.purpleBackground {
  --background: linear-gradient(var(--ion-color-primary) 50%, white 50%);
}