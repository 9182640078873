.paginationItem {
	padding: 0;
	line-height: 16px;
	cursor: pointer;
	--min-height: auto;
	--padding-start: 0;
	--inner-padding-end: 0;
	display: flex;
	align-items: center;
}

.indicateData {
	font-size: 15px;
}

.paginationIcon {
	margin: 0;
	font-size: 14px;
}

.dataPicker {
	flex-grow: 1;
	cursor: pointer;
	--min-height: auto;
}

.dataPickerInfo {
	flex-direction: column;
	width: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 18px;
	text-align: center;
}

.dataPickerData {
	font-weight: bold;
	font-size: 18px;
	line-height: 18px;
}

.isCalendarInvisible {
	display: none;
}