.chatContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat {
  flex-grow: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styleImg {
  margin-bottom: 11px;
  height: 125px;
  width: 77px;
  background-position: center;
  background-image: url('../../assets/images/EmptyMessage.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.styleImgDoctor {
  margin-bottom: 11px;
  height: 125px;
  width: 77px;
  background-position: center;
  background-image: url('../../assets/images/doctor-consultation.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.greenSpan {
  padding: 2px;
  background: green;
}

.redSpan {
  padding: 2px;
  background: red;
}

.styleTitle {
  text-align: center;
  width: 100%;
}

.newMessage {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  background: white;
  align-items: center;
  border-top: rgba(0, 0, 0, 0.1) 1px solid;

  &_inputField {
    margin: 0;
    --padding-bottom: 8px;
    --padding-end: 8px;
    --padding-top: 8px;
    --padding-start: 8px;
    max-height: 200px;
  }

  &_sendButton {
    color: #805ee8;
    margin-right: 8px;
    transform: rotate(45deg);
    font-size: 28px;
  }
}
