.ionSortModal {
  &__title {
    padding-inline: var(--padding-md);
    margin-block: var(--margin-md);
    font-size: 22px;
    text-align: center;
    font-weight: 700;
  }

  &__list {
    &-item {
      padding-inline: var(--padding-md);
      font-size: 14px;
      color: var(--ion-color-dark);
      &Label {

      }

      &RadioBtn {
        width: 24px;
        background-color: rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        margin-inline: 0;
      }
    }
  }
}
