ion-content ion-toolbar {
  --background: transparent;
}

.pageHeader {
  padding-top: 60px;
  padding-bottom: 8px;
}

.toolbar {
  padding: 0 16px;
  margin-bottom: 32px;
  --min-height: auto;
  --border-style: none;
}

.toolbar:last-child {
  margin-bottom: 0;
}

.menuButton {
  color: #686b6f;
}

.pageTitle {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.iconFilter {
  color: #686b6f;
}

.searchbar {
  height: 50px;
  font-size: 16px;
  color: #464646;
  background: #f6f6f6;
  border: 1px solid #e8e8e8;
  border-radius: 100px;
  --padding-start: 16px;
}

.pinqBg {
  --background: var(--ion-color-primary);
}
