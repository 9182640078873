.ionCard {
  position: relative;
  font-family: var(--font-montseratt);
  box-shadow: var(--boxshadow-lg);

  &__container {
    min-height: 240px;
    height: 100%;
    flex-wrap: nowrap;
  }

  &__image {
    flex-shrink: 0;
    width: 35%;

    background-color: var(--ion-color-primary-shade);
    background-position: 50%;
    background-size: cover;
  }

  &__content {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }

  &__header {
    padding: var(--padding-sm);
    padding-bottom: 0;

    &-title {
      margin-bottom: 4px;
      font-size: 14px;
      color: var(--ion-color-dark);
    }

    &-rating {
      &Icons {
        //rating stars styles
      }

      &Length {
        font-family: var(--font-sfprodisplay);
        margin-left: 2px;
        font-size: 12px;
        font-weight: 600;
        color: var(--ion-color-dark);
        line-height: 1.7;
      }
    }
  }

  &__descriptions {
    padding: var(--padding-sm);
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 5px;

      &Icon {
        align-self: start;
        width: 16px;
      }

      &Text {
        font-size: 12px;
        font-weight: 500;
        color: var(--ion-color-dark);
      }
    }
  }

  &__footer {
    flex-shrink: 0;
    padding-block: var(--padding-sm);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--ion-color-primary-tint);

    &-text {
      font-size: 13px;
      color: var(--ion-color-primary-contrast);
      text-shadow: var(--textshadow-md);
    }

    &-icon {
      margin-top: 1px;
      width: 18px;
    }
  }
}
