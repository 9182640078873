.reviewClinic {
  padding: var(--padding-md);
  &_header {
    &-icon {
      height: auto;
    }
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: var(--padding-sm);
    border-bottom: 2px solid #f1f1f1;
    &Content {
      gap: 10px;
      &-rating {
        background-color: var(--ion-color-primary-shade);
        color: var(--ion-color-tertiary-contrast);
        padding: var(--padding-sm);
        border-radius: 5px;
        font-weight: bold;
      }
      &-text {
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        &Tooltip {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  &_characteristics {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-item {
      display: flex;
      flex-direction: column;
      &Title {
        font-size: 12px;
        padding-right: 2px;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &_showAll {
    align-items: center;
    gap: 10px;
    padding-block: var(--padding-md);
    &-text {
      font-size: 12px;
      font-weight: bold;
      color: var(--ion-color-primary);

    }
    &-icons {
      flex-direction: column;
      font-size: 12px;
      color: var(--ion-color-primary);

      &ArrowUp {
        margin-bottom: -6px;
      }
      &ArrowDown {

      }
    }
  }

  &_empty {
    padding: var(--padding-md);
    flex-direction: column;
    &Content {
      gap: 10px;
      align-items: center;
      font-weight: bold;
      margin-bottom: 10px;
      padding-bottom: var(--padding-sm);
      border-bottom: 2px solid #f1f1f1;

      &-rating {
        background-color: var(--ion-color-primary-shade);
        color: var(--ion-color-tertiary-contrast);
        padding: var(--padding-sm);
        border-radius: 5px;
        font-weight: bold;
      }

      &-text {
        font-size: 16px;
      }
    }

    &Characteristics {
      font-size: 12px;
      padding-right: 2px;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
    }
  }
}

