.locationGridContainer {
  padding-top: var(--ion-safe-area-top, 0) !important;
  position: fixed;
  background: white;
  width: 100%;
  left: 0;
  z-index: 9999;
  box-shadow: 0px 2px 2px 0px #c3c3c3c9;
  transition: opacity .3s ease;

  &__searchbar {
    padding-right: 10px;
  }

  .searchColumn {
    display: flex;

    .search-button {
      width: 100%;
      flex-grow: 1;

      &__container {
        width: 100%;
      }

      &__text {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }


  }

  .toolbar__back-button {
    font-size: 35px;
  }
}

