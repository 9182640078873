.container {
  padding-bottom: 1rem;
}

.username {
  padding: 13px 16px 19px;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

.userInfoItem {
  margin: 0 16px;
  font-size: 16px;
  line-height: 19px;
  --min-height: 49px;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}

.userInfoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.badge {
  margin-right: 0;
  color: #fff;
}

.popoverInner {
  padding: 10px;
}

.button {
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
  --padding-end: 0;
}

.phoneDataToggle {
  --background-checked: var(--ion-color-primary-tint);
  padding-right: 0;
}
