
.header {
    padding-right: 85px;
    // margin-right: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: white;
}

.purpleBackground {
    --background: linear-gradient(var(--ion-color-primary) 50%, white 50%);
}

.calendarWrapper {
    background: white;
}