.container {
  height: 180px;
  //$primary: var(--ion-color-primary);
  background: #ffe5ff;
  color: var(--ion-color-primary);
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  align-items: center;
  border: 2px dashed var(--ion-color-primary);
  border-radius: 10px;
  font-weight: bold;
  //&:hover {
  //  opacity: .6;
  //}

  .addButton {
    display: flex;
    flex-direction: column;
    align-items: center;

    .uploadIcon {
      font-size: 28px;
      margin-bottom: 6px;
    }
  }
}

.containerUploaded {
  border-style: solid;
  color: purple;
}

.inputField {
  display: none;
}
