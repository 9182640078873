.ionButtons {
  justify-content: space-between;
  position: fixed;
  padding-right: 12%;
  width: 100%;
  padding-block: 10px;
  box-shadow: var(--boxshadow-sm);
  background-color: var(--ion-color-primary-contrast);

  &__btn {
    &-icon {
      color: var(--ion-color-primary);
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }

    &-text {
      text-align: center;
      font-size: 20px;
      color: var(--ion-color-dark);
    }
  }
}
