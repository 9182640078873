.img {
  overflow: hidden;
  object-fit: cover;
}

.thumb {
  @extend .img;
  --size: 100%;
  height: 100px;
}

.container {
  margin: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 129px;
}

.input {
  width: 100%;
  border: none;
  outline: none;
}

.inputContainer {
  @extend .container;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  color: #bdbdbd;
  line-height: 10px;
  --placeholder-color: #bdbdbd;
  --padding-start: 16px;
  .durationShowField{
    text-align: right;
    .test{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 1px;
    }
  }
}

.titleContainer{
  @extend .inputContainer;
  color: #333333;
}

.fileContainer {
  margin-bottom: 15px;
}

.datetimeInput {
  --padding-start: 0px;
  font-style: normal;
  font-weight: 500;
  --placeholder-color: #bdbdbd;
  font-size: 16px;
}

.iconInput {
  color: #666666;
}

.inputNumber {
  text-align: right;
  color: #333333;
}

.triggerInput {
  height: 100px;
  color: #333333;
  --placeholder-color: #bdbdbd;
  --placeholder-opacity: 1;
}

.suggestionsList {
  @extend .container;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin-left: 14px;
  width: 16px;
  opacity: 1 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  margin: 0;
}

.inputButtonsContainer{
  --inner-padding-end: 0;
  --highlight-height: 0;
  ion-input{
    text-align: center;
    --padding-end: 0;
    --padding-start: 0;
    width: 60px;
  }
}

.active{
  color: #333333;
}

.downloadButtonSpinner {
  padding-left: 2rem;
}
