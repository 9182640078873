.item{
  --padding-start: 33px;
  --padding-end: 16px;
  --inner-padding-end: 12px;

  &Edit{
    &Icon{
      color: #686b6f;
    }
  }

  &Delete{
    &Icon{}
  }
}

.label{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  color: red;
}

.badge {
  color: #fff;
}