
.toolbar {
  padding-top: calc(var(--ion-safe-area-top,0) + 10px) !important;

  &__back-button {
    font-size: 35px;
    margin-top: 6px;
  }
}


.backdrop {
  transition: all .3s ease;
  opacity: 0;
  z-index: 101;
  &--visible {
    opacity: .4;
  }
}

.search-two-field-form {
  position: absolute;
  top: 0;
  padding-top: var(--ion-safe-area-top, 0);
  opacity: 0;
  z-index: 103;
  //margin-top: 3px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid black;
  transition: all .3s ease;

  &--visible {
    opacity: 1;
  }

  .list-item {
    --padding-start: 14px;
    --color-focused: #444BFF;
    --color-activated: #444BFF;
    --highlight-color-focused: #444BFF;
    --color-hover: #444BFF;
    --background-hover: transparent;
    --background-focused: transparent;
    --background-activated: transparent;

    ion-label {
      margin-left: 18px;
    }
  }

  .searchColumn {
    padding-top: 8px;
  }

  .search-field-with-icon {
    ion-icon {
      margin-right: 8px;
    }

    ion-item {
      --padding-start: 0;

      ion-input {
        text-overflow: ellipsis;
      }
    }

    &__trim-date-input {
      margin-bottom: 8px;
    }
  }



  ion-button {
    font-weight: bold;
  }
}


.content {
  .list-item {
    --color-focused: #444BFF;
    --color-activated: #444BFF;
    --highlight-color-focused: #444BFF;
    --color-hover: #444BFF;
    --background-hover: transparent;
    --background-focused: transparent;
    --background-activated: transparent
  }
  .timeIcon {
    margin-right: 12px;
  }
  .search-result-icon {
    margin-right: 12px;
  }
}


.padding-top-zero {
  padding-top: 0;
}

.searchColumnItem {
  --min-height: 36px;
  --padding-start: 0;
}
