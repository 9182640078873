.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.styleImg {
    margin-bottom: 11px;
    height: 125px;
    width: 77px;
    background-position: center;
    background-image: url('../../assets/images/EmptyState.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.styleTitle {
    text-align: center;
    width: 100%;
}
