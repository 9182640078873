.disclosureButton {
  width: 93%;
  margin-inline: auto;
  margin-bottom: var(--margin-sm);
  padding: var(--padding-sm);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: var(--font-sfprodisplay);
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--ion-color-primary-contrast);
  text-shadow: var(--textshadow-md);
  background-color: var(--ion-color-tertiary);
  border-radius: var(--rounded-md);
}