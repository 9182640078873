.chat {


  &_messageList {
    padding-top: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

.day{
  align-self: center;
  background-color:rgba(148, 0, 211, 0.4);;
  color: white;
  line-height: 0;
  padding: 14px 8px 16px 8px;
  font-weight: 600;
  border-radius: 50px;

}

.message {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding: 4px 8px;
  margin: 4px 16px;
  max-width: 70vw;
  background-color: #f5e1fe;
  border-radius: 12px;
  white-space: pre-line;
}

.message__incoming {
  background-color: rgb(233, 233, 233);
  color: black;
  align-self: flex-start;
}
.message_time{
  margin-left: 2rem;
  align-self: flex-end;
  font-size: 12px;
}
