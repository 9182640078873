.FooterBar {
  &__icon {
    fill: lightgrey;
    width: 32px;
    height: 32px;
    transition: 200ms;
    &.active {
      fill: var(--ion-color-primary);
    }

    &--home {
      width: 38px;
      height: 38px;
    }
  }
}
