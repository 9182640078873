.ionGrid {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.inputFieldsContainer {
	//flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.button {
	height: 51px;
	text-transform: none;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: unset;
	color: #fff;
	--border-radius: 100px;
}

.greetingItem {
  align-self: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #333;
}

.inputItem {
	justify-content: space-between;
  color: #000;
	margin-top: 16px;
	border: 1px solid #e4e4e4;
	border-radius: 8px;
	width: 100%;
	--min-height: 54px;
}

.switchHomeLocationButton {
	margin-left: auto;
}
.switchRemoveAvatar {
	text-transform: none;
	align-self: center;
}

.inputSelect {
	height: 55px;
	border: 1px solid #e8e8e8;
	padding: 0 30px 0 20px;
	border-radius: 8px;
	margin: 15px 0 0 0;
	font-size: 16px;
	&::part(placeholder) {
		color: #000;
		opacity: 0.5;
	}

	&::part(text) {
		color: #000;
  }
  
  &::part(icon) {
		color: #333;
	}
}

.datetime {
  --placeholder-color: #7f7f7f;
}

.checkboxItem {
	color: #666;
	--padding-start: 16px;
	--inner-padding-end: 20px;
}

.icon {
	color: #666;
}

.buttonRow {
  padding: 21px 16px;
}
