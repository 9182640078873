.ionGrid {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.firstHalf {
  flex-grow: 1;
}

.secondHalf {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;
}

.geolocationImg {
    width: 144px;
    height: 144px;
}

.button {
  height: 51px;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: unset;
  color: #fff;
  --border-radius: 100px;
}

.buttonClear {
  font-weight: 600;
}

.buttonIcon {
    width: 20px;
    margin-right: 8px;
}

.geolocationButtonRow {
    height: 40%;
    align-items: flex-end;
}

.imageRow {
    align-items: flex-end;
    justify-content: center;
    // height: 100%;
    width: 100%;
}

.textRow {
  padding-top: 10%;
  align-items: flex-end;
  justify-content: center;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}
