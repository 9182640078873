.iconInput {
  margin-left: 10px;
  margin-top: -3px;
  color: #666666;
}

.searchBarIcon{
  font-size: 30px;
  margin-right: 5px;
  color: #b05db0;
}

.searchBarIcon:active, .searchBarIcon:hover{
  font-size: 30px;
  opacity: 50%;
  color: #b05db0;
}

.searchBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: white;
  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  padding-bottom: 6px;
}

.searchBar{
  justify-content: center;
  color: #000;
  margin-left: 4px;
  margin-right: 4px;
  left: auto;
  padding: 6px 6px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  width: 98%;
  height: 3rem;
  --min-height: 54px;
  --box-shadow:none;
}

.inputString {
  text-align: left;
  color: #333333;
}

.noResultFound {
  text-align: center;
}

.suggestionsList {
  margin: 15px;
}

.medicineList {
  margin-bottom: auto;
  //margin-top: 6px;
  margin-top: 69px;
}