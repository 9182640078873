.img {
  overflow: hidden;
  object-fit: cover;
}

.thumb {
  @extend .img;
  --size: 100%;
  height: 100px;
}

.container {
  margin: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.inputContainer {
  @extend .container;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  color: #bdbdbd;
  line-height: 19px;
  --placeholder-color: #bdbdbd;
  --padding-start: 16px;
  &Description {
    height: 110px;
    overflow-y: scroll;
  }

  .durationShowField{
    text-align: right;
    .test{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 1px;
    }
  }
}

.datetimeContainer{
  @extend .inputContainer;
  color: #333333;

  ion-label {
    --padding-start: 0px;
    font-style: normal;
    font-weight: 500;
    --placeholder-color: #bdbdbd;
    font-size: 16px;
  }
}

.datetimeInput {
  --padding-start: 0px;
  font-style: normal;
  font-weight: 500;
  --placeholder-color: #bdbdbd;
  font-size: 16px;
}

.iconInput {
  color: #666666;
}

.inputNumber {
  text-align: right;
  color: #333333;
}

.triggerInput,
.descriptionInput {
  height: 100px;
  color: #333333;
  --placeholder-color: #bdbdbd;
  --placeholder-opacity: 1;
}

.descriptionInput {
  height: auto;
}

.locationFieldContainer {
  --border-color: var(--ion-color-primary);
}

.locationField {
  touch-action: none;
}

.suggestionsList {
  @extend .container;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin-left: 14px;
  width: 16px;
  opacity: 1 !important;
}

.listSelect {
  height: 44px;
  border: 1px solid #e8e8e8;
  padding: 0 30px 0 15px;
  border-radius: 8px;
  margin: 15px;
  font-size: 16px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  margin: 0;
}

.inputButtonsContainer{
  --inner-padding-end: 0;
  --highlight-height: 0;
  ion-input{
    text-align: center;
    --padding-end: 0;
    --padding-start: 0;
    width: 60px;
  }
}

.active{
  color: #333333;
}

.geolocationContainer {

  &> * {
    margin-bottom: 3px;
  }
}
.geolocation {
  --padding-start: 14px;
  width: calc(100% - 6px);

  &Icon {
    font-size: 22px;
  }
  &Title {
    font-size: 20px !important;
    margin-left: 6px;
    color: var(--ion-color-primary);

    &> * {
      vertical-align: middle;
    }
  }

  &Status {
    color: #831e7a;
    margin-bottom: 6px;
  }

  &ChangeButton {
    --color-activated: var(--ion-color-primary);
  }
}

.componentContainer {
  height: calc(100vh - 124px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
