.ratingBar-container {
  height: 8px;
  width: 100%;
  background-color: #ececec;
  border-radius: 50px;
}

.ratingBar-filter {
  height: 100%;
  border-radius: inherit;
  text-align: right;
  background-color: var(--ion-color-primary-tint);

}

.ratingBar-label {
  padding: 5px;
  color: white;
  font-weight: bold;
}