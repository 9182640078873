.contentWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 106px);
}

.medicineInfo {
  flex-grow: 1;
  align-content: flex-start;
}

.list {
  width: 100%;
}

.listHeader {
  padding: 0 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.medicineInfoItem {
  margin: 11px 16px;
  font-size: 16px;
  line-height: 19px;
  color: #bdbdbd;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  --min-height: 50px;
  --padding-start: 16px;
  --padding-end: 12px;
  --inner-padding-end: 0;
}

.medicineInfoInput {
  @extend .medicineInfoItem;
  color: #000;
}

.medicineInfoItems {
  display: flex;
}

.medicineInfoItemInner {
  width: 100%;
  margin: 3px 0px;
  --padding-start: 0px;
  border-bottom: 1px solid #d4d4d4;
  --border-color: white;
}

.medicineInfoItemInput {
  width: 80%;
  margin-left: 0px;
  color: #bdbdbd;
}

.medicineInfoItemAdd {
  margin: 11px 16px;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  --min-height: 50px;
  --padding-start: 16px;
  --padding-end: 12px;
  --inner-padding-end: 0;
}

.input {
  --placeholder-opacity: 1;
}

.inputNumber {
  color: #333;
  --padding-end: 0;
}

.inputSelect {
  max-width: 100%;
  --placeholder-opacity: 1;
}

.icon {
  margin-right: 0;
  margin-left: 30px;
  color: #686b6f;
}

.disabledIcon {
  opacity: 0.3;
}

.iconLabel {
  margin-left: 5px;
  color: #000;
  transform: translateY(3px);
}

.button {
  width: 100%;
  height: 51px;
  margin: 16px 16px 20px;
  font-size: 16px;
  line-height: 19;
  color: #fff;
  text-transform: none;
  --border-radius: 100px;
}

.medicineSelect {
  height: 50px;
  border: 1px solid #e8e8e8;
  padding: 0 16px;
  border-radius: 8px;
  margin: 15px;
}

.inputLabel {
  color: #333333;
}

.medicineInfoItemAddButton {
  margin-left: 0px;
  color: #ffffff;
  height: 35px;
  width: 35px;
  --border-radius: 18px;
}

.medicineInfoItemInputDel {
  @extend .medicineInfoItemAddButton;
  margin-left: auto;
}

.medicineInfoItemAddText {
  margin-left: 5%;
}
