.menuContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
}

.menuHeaderContainer {
  background-color: var(--ion-color-primary);
}

.menuHeader {
  align-items: center;
  margin-top: env(safe-area-inset-top);
  margin-bottom: 10px;

  .headerImgContainer {
    display: flex;
    justify-content: center;
  }
}

.username {
  flex: 1;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #ffffff;
  cursor: pointer;
}

.connectionStatusContainer {
  justify-content: center;
  padding-top: 10px;

  ion-icon {
    align-self: center;
    position: relative;

    &.not-connected::after {
      content: '';
      width: 1px;
      height: 18px;
      background-color: white;
      position: absolute;
      top: -3px;
      left: 6px;
      transform: skew(50deg, 116deg);
    }
  }
}

.connectionStatus {
  @extend .username;
  flex: 0;
  margin-left: 8px;
  font-size: 12px;
  line-height: 15px;
  cursor: unset;
}

.icon {
  margin-right: auto;
  width: 22px;
  height: 17px;
  padding-top: 10px;
}

.headerImg {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.pageButton {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  padding-left: var(--padding-sm);

  color: #404040;
}

.lastElement {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  padding-left: var(--padding-sm);

  color: var(--ion-color-primary);
}

.purpleBackground {
  --background: linear-gradient(var(--ion-color-primary) 50%, white 50%);
}

.badge {
  padding: 5px;
  border: 1px solid white;
}
