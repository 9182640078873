.profilePhotoContainer {
  margin: 26px 0 10px 0;
  height: 157px;
  text-align: center;
  position: relative;
}

.profilePhoto {
  position: relative;
  z-index: 10;
  width: 157px;
  height: 157px;
  border-radius: 50%;
  object-fit: cover;
}

.placeholderWrapper {
  opacity: .7;
  .plusIcon{
    position: absolute;
    color: purple;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    font-size: 100px;
    z-index: 100;
  }
}
