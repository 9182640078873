.headContent {
  position: relative;
}

.screen {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform .3s ease;
}

.screenActive {
  transform: translateX(0);
}

// .backdrop {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     transform: translateY(100vh);
//     background-color: rgba(255,255,255,1);
//     transition: background-color .3s ease;
// }

// .backdropActive {
//     transform: translateY(0);
//     background-color: rgba(255,255,255,.3);
// }
